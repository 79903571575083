import React from "react";
import "./index.scss";
import { Button, Card, Flex, Typography } from "antd";
import constants from "../../utils/constants";
import { CheckCircleOutlined, HomeOutlined } from "@ant-design/icons";

const SuccessPage = ({ name }: { name: string | null }) => {
  const { Paragraph, Text, Title } = Typography;

  const handleHomeRedirect = () => {
    window.location.href = constants.HOST_URL;
  };

  return (
    <div className="booking-confirm-container">
      <div className="success-card">
        <div className="header">
          <Title level={2}>Thank You!</Title>
          <Flex vertical>
            <Title level={4}>
              Thank you for raising enquiry request for {name} from{' '}
              <span className="highlighted-text">TurfBooking.in</span>
            </Title>
            <Text>
              <big>
                Our team will connect with you shortly to confirm and process
                your booking.
              </big>
            </Text>
          </Flex>
        </div>
        <Title
          level={1}
          style={{
            fontSize: 128,
          }}
          type="success"
        >
          <CheckCircleOutlined />
        </Title>
        <Flex wrap="wrap" justify="center" align="center" gap={8}>
          <Button
            shape="round"
            type="primary"
            href={constants.HOST_URL + "/feedback"}
            target="_blank"
          >
            Tell Us About Your Experience
          </Button>
          <Button shape="round" type="primary" onClick={handleHomeRedirect}>
            Home <HomeOutlined />
          </Button>
        </Flex>
      </div>
    </div>
  );
};

export default SuccessPage;
