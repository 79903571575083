import constants from "../utils/constants";

export const turfData = [
  // Done
  {
    name: "The Offside Sports Turf",
    domainKey: "The Offside Sports Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/offside_turf_indore/offside_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/offside_turf_indore/offside_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/offside_turf_indore/offside_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "7500",
          pitchDimensions: {length: 115, width: 65, height: 35},
          price: {
            weekday: {
              regularHours: 1400,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1200,
              // },
            },
            weekend: {
              regularHours: 1400,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1200,
              // },
            },
          },
        },
        {
          area: "15000",
          pitchDimensions: {length: 130, width: 115, height: 35},
          price: {
            weekday: {
              regularHours: 2600,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1300,
              // },
            },
            weekend: {
              regularHours: 2600,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1300,
              // },
            },
          },
        },
      ],
      address: "Near Treasure Town Opp. Treasure Prime, Bijalpur, Indore",
      location: {
        latitude: "22.66659110421007",
        longitude: "75.8465476261722",
      },
      actualContact: "6269773000 / 6269772000",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "10 am",
        closingTime: "11 pm",
        isAvailable24Hours: false,
      },
      isRainProtected: true,
      starRating: "4.7",
      hasCafe: true,
    },
    userName: "The-Offside-Sports-Turf",
  },
  // Done
  {
    name: "Dugout Turf",
    domainKey: "Dugout Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/dugout_turf_indore/dugout_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/dugout_turf_indore/dugout_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/dugout_turf_indore/dugout_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "9000",
          pitchDimensions: {length: 120, width: 75, height: 31},
          price: {
            weekday: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
            weekend: {
              regularHours: 1600,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
          },
        },
      ],
      address:
        "Ring road, robot square, MR 9 Rd, near City bus depot, Shardha Shri Colony, Indore",
      location: {
        latitude: "22.740207 ",
        longitude: "75.901896",
      },
      actualContact: "9993224054 / 9993222554",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "6 am",
        closingTime: "12 am",
        isAvailable24Hours: false,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: true,
    },
    userName: "Dugout-Turf",
  },
  //  Done
  {
    name: "The Escobar Turf Arena",
    domainKey: "The Escobar Turf Arena",
    bannerImageUrl:
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/escobar_turf_indore/escobar_banner.jpg",
    logo: "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/escobar_turf_indore/escobar_logo.png",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/escobar_turf_indore/escobar_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/escobar_turf_indore/escobar_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/escobar_turf_indore/escobar_3.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/escobar_turf_indore/escobar_4.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "5000",
          pitchDimensions: {length: 98, width: 50, height: 35},
          price: {
            weekday: {
              regularHours: 800,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 700,
              },
            },
            weekend: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 900,
              },
            },
          },
        },
      ],
      address: "145, Bardari Road, near AUROBINDO HOSPITAL, Bhawrasla, Indore",
      location: {
        latitude: "22.793035",
        longitude: "75.845776",
      },
      actualContact: "9109999392 / 7879565656",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: true,
      facilities: [
        "24*7 Available",
        "Covered Turf Area",
        "Turf height: 35 feet",
        "Base: Foam with grass",
        "Equipment: Free ball available, Up to 5 free bats available",
        "Food Park: Large variety of food available",
        "Parking Available",
      ],
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "4.5",
      hasCafe: true,
    },
    userName: "The-Escobar-Turf-Arena",
  },
  // Done
  {
    name: "The Harbour House Sports Cafe and Turf",
    domainKey: "The Harbour House Sports Cafe and Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/harbour_turf_indore/harbour_1.JPG",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/harbour_turf_indore/harbour_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/harbour_turf_indore/harbour_3.JPG",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "8000",
          pitchDimensions: {length: 106, width: 76, height: 45},
          price: {
            weekday: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
            weekend: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
          },
        },
      ],
      address: "179/2, Sarvanand Nagar, Pipliya Rao, Indore",
      location: { latitude: "22.6833961", longitude: "75.8592783" },
      actualContact: "8817864009",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: true,
      // mobOffers: [
      //   "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/harbour_turf_indore/harbour_banner_mobile_1.png"
      // ],
      // webOffers: [
      //   "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/harbour_turf_indore/harbour_banner_web_1.png"
      // ],
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: true,
    },
    userName: "The-Harbour-House-Sports-Cafe-and-Turf",
  },
  // Done
  {
    name: "Pavilion Turf Dewas Naka",
    domainKey: "Pavilion Turf Dewas Naka",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/pavalion-I_turf_indore/pavalion_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/pavalion-I_turf_indore/pavalion-I_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/pavalion-I_turf_indore/pavalion-I_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "7000",
          pitchDimensions: {length: 100, width: 90, height: 35},
          price: {
            weekday: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900,
              // },
            },
            weekend: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900,
              // },
            },
          },
        },
        {
          area: "7000",
          pitchDimensions: {length: 100, width: 90, height: 35},
          price: {
            weekday: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900,
              // },
            },
            weekend: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900,
              // },
            },
          },
        },
      ],
      address:
        "Upside Land Rover Showroom, Mangal Compound, MR-11, Ring Rd, Dewas Naka, P-77, Indore",
      location: {
        latitude: "22.770927",
        longitude: "75.905098",
      },
      actualContact: "9584168881",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false,
    },
    userName: "Pavilion-Turf-Dewas-Naka",
  },
  // Done
  {
    name: "Pavilion Turf Nipania",
    domainKey: "Pavilion Turf Nipania",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/pavalion-I_turf_indore/pavalion_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/pavalion-II_turf_indore/pavalion-II_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/pavalion-II_turf_indore/pavalion-II_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "7000",
          pitchDimensions: {length: 100, width: 90, height: 35},
          price: {
            weekday: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900,
              // },
            },
            weekend: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900,
              // },
            },
          },
        },
      ],
      address: "Nipania near Eicher Showroom, Indore",
      location: { latitude: "22.761702", longitude: "75.927303" },
      actualContact: "9584168881",
      displayContact: "9754613641",
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false,
    },
    userName: "Pavilion-Turf-Nipania",
  },
  // Done
  {
    name: "Pavilion Turf Mahalaxmi Nagar",
    domainKey: "Pavilion Turf Mahalaxmi Nagar",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/pavalion-I_turf_indore/pavalion_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/pavalion-III_turf_indore/pavalion-III_2.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "7000",
          pitchDimensions: {length: 100, width: 90, height: 35},
          price: {
            weekday: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900,
              // },
            },
            weekend: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900,
              // },
            },
          },
        },
      ],
      address: "Mahalaxmi Nagar, Indore",
      location: { latitude: "22.770927", longitude: "75.905098" },
      actualContact: "9584168881",
      displayContact: "9754613641",
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false,
    },
    userName: "Pavilion-Turf-Mahalaxmi-Nagar",
  },
  // Done
  {
    name: "The Legends Turf",
    domainKey: "The Legends Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/legends_turf_indore/legends_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/legends_turf_indore/legends_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/legends_turf_indore/legends_3.JPG",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 85, width: 56, height: 30},
          price: {
            weekday: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900,
              // },
            },
            weekend: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900,
              // },
            },
          },
        },
      ],
      address:
        "Circle, Shakti Tol Kanta, 1-A, Vijay, near Bridge, Udhyog Nagar, Palda Indore, Madhya Pradesh",
      location: {
        latitude: "22.689086",
        longitude: "75.885232",
      },
      actualContact: "7024741611",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false,
    },
    userName: "The-Legends-Turf",
  },
  // Done
  {
    name: "Tims Turf & Cafe",
    domainKey: "Tims Turf and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/tims_turf_indore/tims_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/tims_turf_indore/tims_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/tims_turf_indore/tims_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "8000",
          pitchDimensions: {length: 105, width: 70, height: 35},
          price: {
            weekday: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
            weekend: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
          },
        },
      ],
      address: "19, Amitesh Nagar, Krishi Mandi Indore, Madhya Pradesh",
      location: {
        latitude: "22.681606",
        longitude: "75.848435",
      },
      actualContact: "8871843841",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "4.5",
      hasCafe: true,
    },
    userName: "Tims-Turf-and-Cafe",
  },
  // Done
  {
    name: "AV Turf & Cafe",
    domainKey: "AV Turf and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/av_turf_indore/av_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/av_turf_indore/av_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/av_turf_indore/av_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "9000",
          pitchDimensions: {length: 100, width: 60, height: 32},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 900,
              },
            },
            weekend: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 900,
              },
            },
          },
        },
      ],
      address:
        "Halka No. 255/2 12, Mumbai - Agra National Highway opp. SAGE UNIVERSITY opp. Truba College Indore",
      location: {
        latitude: "22.639771",
        longitude: "75.858361",
      },
      actualContact: "9310444429",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: true,
    },
    userName: "AV-Turf-and-Cafe",
  },
  // Done
  {
    name: "Club 19 Club & Cafe",
    domainKey: "Club 19 Club and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/club19_turf_indore/club19_1.png",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/club19_turf_indore/club19_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/club19_turf_indore/club19_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "5500",
          pitchDimensions: {length: 110, width: 70, height: 35},
          price: {
            weekday: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400,
              },
            },
            weekend: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400,
              },
            },
          },
        },
        {
          area: "6500",
          pitchDimensions: {length: 110, width: 70, height: 35},
          price: {
            weekday: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400,
              },
            },
            weekend: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400,
              },
            },
          },
        },
      ],
      address: "PSP-2, Scheme no. 94, near Hans Travels, Ring Road, Indore",
      location: {
        latitude: "22.697125",
        longitude: "75.903493",
      },
      actualContact: "9669719000",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "Club-19-Club-and-Cafe",
  },
  // Done
  {
    name: "Buddy's Astro Turf",
    domainKey: "Buddys Astro Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/buddys_turf_indore/buddy_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/buddys_turf_indore/buddy_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/buddys_turf_indore/buddy_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "7000",
          pitchDimensions: {length: 100, width: 65, height: 33},
          price: {
            weekday: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400,
              },
            },
            weekend: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400,
              },
            },
          },
        },
      ],
      address: "8 MG Road Near Buddy's Cafe, Indore",
      location: {
        latitude: "22.719393",
        longitude: "75.866275",
      },
      actualContact: "9644644442",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "6 am",
        closingTime: "12 am",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false,
    },
    userName: "Buddys-Astro-Turf",
  },
  // Done
  {
    name: "B3 Turf",
    domainKey: "B3 Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/b3_turf_indore/b3_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/b3_turf_indore/b3_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/b3_turf_indore/b3_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "8000",
          pitchDimensions: {length: 110, width: 68, height: 36},
          price: {
            weekday: {
              regularHours: 1400,
              happyHours: {
                range: "6:00AM-5:00PM",
                price: 1300,
              },
            },
            weekend: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-5:00PM",
                price: 1300,
              },
            },
          },
        },
      ],
      address:
        "Just, Press Complex, Indore - 452003 (Behind Shreemaya Hotel, Near Lig Square)",
      location: {
        latitude: "22.738407",
        longitude: "75.893478",
      },
      actualContact: "8223030405 / 9303792304",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "B3-Turf",
  },
  // Done
  {
    name: "Yashwant Plaza Turf",
    domainKey: "Yashwant Plaza Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/yashwantPlaza_turf_indore/yashwant_plaza_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/yashwantPlaza_turf_indore/yashwant_plaza_2.JPG",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/yashwantPlaza_turf_indore/yashwant_plaza_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "5000",
          pitchDimensions: {length: 95, width: 56, height: 32},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 900,
              },
            },
            weekend: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 900,
              },
            },
          },
        },
        {
          area: "5500",
          pitchDimensions: {length: 100, width: 60, height: 32},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
          },
        },
      ],
      address:
        "Yashwant Plaza, Terrace Station Road, behind TI Next Mall, Indore",
      location: {
        latitude: "22.719393",
        longitude: "75.869861",
      },
      actualContact: "9993022345",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "Yashwant-Plaza-Turf",
  },
  {
    name: "Sprint & Smash Turf",
    domainKey: "Sprint and Smash Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sprintAndSmash_turf_indore/sprint_and_smash_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sprintAndSmash_turf_indore/sprint_and_smash_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sprintAndSmash_turf_indore/sprint_and_smash_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 90, width: 60, height: 30},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
          },
        },
      ],
      address:
        "8th Floor, Krishna Building PU4 near Medanta Hospital Vijay Nagar Indore",
      location: {
        latitude: "22.748827",
        longitude: "75.897221",
      },
      actualContact: "9977055552",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "Sprint-and-Smash-Turf",
  },
  // Done
  {
    name: "Skybox Turf",
    domainKey: "Skybox Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/skybox_turf_indore/skybox_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/skybox_turf_indore/skybox_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/skybox_turf_indore/skybox_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "5000",
          pitchDimensions: {length: 98, width: 60, height: 35},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
          },
        },
      ],
      address:
        "Tulsi Tower, 22/3 South Tukoganj, Street No.1 Geeta Bhavan Square A.B., Geeta Bhawan, Indore",
      location: {
        latitude: "22.719073",
        longitude: "75.883944",
      },
      actualContact: "8440984409",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false,
    },
    userName: "Skybox-Turf",
  },
  // Done
  {
    name: "Wings Turf and Cafe",
    domainKey: "Wings Turf and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/wings_turf_indore/wings_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/wings_turf_indore/wings_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/wings_turf_indore/wings_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "8000",
          pitchDimensions: {length: 116, width: 75, height: 45},
          price: {
            weekday: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
            weekend: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
          },
        },
      ],
      address: "Turf, Devendra Nagar Road, Tejpur Gadbadi, Indore",
      location: {
        latitude: "22.684277",
        longitude: "75.838844",
      },
      actualContact: "8109426669 / 9039578261 ",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false,
    },
    userName: "Wings-Turf-and-Cafe",
  },
  // Done
  {
    name: "Winners Turf and Cafe",
    domainKey: "Winners Turf and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/winners_turf_indore/winnners_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/winners_turf_indore/winnners_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/winners_turf_indore/winnners_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "7000",
          pitchDimensions: {length: 95, width: 60, height: 32},
          price: {
            weekday: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
            weekend: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1300,
              },
            },
          },
        },
      ],
      address:
        "Plot Number 6, opp. Choitram Mandi, Gate, Amitesh Nagar, Krishi Mandi, Indore",
      location: {
        latitude: "22.681524",
        longitude: "75.851389",
      },
      actualContact: "9893789745",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "Winners-Turf-and-Cafe",
  },
  // Done
  {
    name: "Winners Arena Turf and Cafe",
    domainKey: "Winners Arena Turf and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/winners_arena_turf_indore/winners_arena_1.png",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/winners_arena_turf_indore/winners_arena_2.jpg",
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "5500",
          pitchDimensions: {length: 90, width: 50, height: 29},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100
              }
            },
            weekend: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200
              }
            },
          }
        }
      ],
      address:
        "Manikbagh, Raj Twp, Indore",
      location: { latitude: "22.689989", longitude: "75.856283" },
      actualContact: "9893789745",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "3.5",
      hasCafe: true
    },
    userName: "Winners-Arena-Turf-and-Cafe",
  },
  // Done
  {
    name: "MP 09 Turf and Cafe",
    domainKey: "MP 09 Turf and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/mp09_turf_indore/mp09_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/mp09_turf_indore/mp09_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/mp09_turf_indore/mp09_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 98, width: 60, height: 35},
          price: {
            weekday: {
              regularHours: 900,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800,
              },
            },
            weekend: {
              regularHours: 900,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800,
              },
            },
          },
        },
      ],
      address:
        "350/2, pipliyahana, near chameli park, service road, Eastern Ring Rd, Indore",
      location: {
        latitude: "22.713060",
        longitude: "75.904632",
      },
      actualContact: "8109988889",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "MP-09-Turf-and-Cafe",
  },
  // Done
  {
    name: "Worldcup Turf",
    domainKey: "Worldcup Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/worldcup_turf/worldcup_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/worldcup_turf/worldcup_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/worldcup_turf/worldcup_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "6500",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 900,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
          },
        },
      ],
      address:
        "Worldcup Under The Overbridge, Worldcup Square, near Papa Bakery, Indore",
      location: {
        latitude: "22.708098",
        longitude: "75.902836",
      },
      actualContact: "9425936078",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "10 am",
        closingTime: "11 pm",
        isAvailable24Hours: false,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: false,
    },
    userName: "Worldcup-Turf",
  },
  {
    name: "BackYard Arena Turf",
    domainKey: "BackYard Arena Turf",
    bannerImageUrl: "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/backyardArena_turf_indore/backyard_banner.jpg",
    logo: "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/backyardArena_turf_indore/backyard_logo.jpeg",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/backyardArena_turf_indore/backyard_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/backyardArena_turf_indore/backyard_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/backyardArena_turf_indore/backyard_3.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/backyardArena_turf_indore/backyard_4.jpg"
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "5500",
          price: {
            weekday: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900
              // }
            },
            weekend: {
              regularHours: 1500,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1100
              // }
            },
          }
        }
      ],
      address:
        "Onam Plaza, RoofTop, AB Rd, Near Industry House, Old Palasia, Indore",
      location: {
        latitude: "22.708098",
        longitude: "75.902836",
      },
      actualContact: "8989775959",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: true,
      // mobOffers: [],
      // webOffers: [],
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "BackYard-Arena-Turf",
  },
  {
    name: "Knock Out Turf",
    domainKey: "Knock Out Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/knockOut_turf_indore/knockout_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/knockOut_turf_indore/knockout_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/knockOut_turf_indore/knockout_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400,
              },
            },
            weekend: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400,
              },
            },
          },
        },
      ],
      address: "Mannat Garden, Dhar Rd, Green Park Colony, Indore",
      location: {
        latitude: "22.703611",
        longitude: "75.807601",
      },
      actualContact: "9238764656",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "5.0",
      hasCafe: false,
    },
    userName: "Knock-Out-Turf",
  },
  {
    name: "Champion's Turf & Cafe",
    domainKey: "Champions Turf and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/champions_turf_indore/champion_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/champions_turf_indore/champion_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/champions_turf_indore/champion_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400,
              },
            },
            weekend: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400,
              },
            },
          },
        },
      ],
      address:
        "Plot No. 11/12, Tirupati Industrial State, MR 10 Rd, near Nirvana Garden, Toll Naka, Kumedi, Indore",
      location: {
        latitude: "22.786375",
        longitude: "75.865509",
      },
      actualContact: "8779568578",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "Champions-Turf-and-Cafe",
  },
  {
    name: "Town Turf Indore",
    domainKey: "Town Turf Indore",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/towns_turf_indore/town_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/towns_turf_indore/town_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/towns_turf_indore/town_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "3600",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-7:00PM",
                price: 700,
              },
            },
            weekend: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-7:00PM",
                price: 700,
              },
            },
          },
        },
      ],
      address:
        "4th Floor, Roof Top, Bakhatgarh Towers, opp. Apna Sweets, New Palasia, Indore",
      location: {
        latitude: "22.727745",
        longitude: "75.885359",
      },
      actualContact: "7974611976 / 9229205550",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false,
    },
    userName: "Town-Turf-Indore",
  },
  {
    name: "STROKE TURF BY OYE INDORI",
    domainKey: "STROKE TURF BY OYE INDORI",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/stroke_turf_indore/stroke_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/stroke_turf_indore/stroke_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/stroke_turf_indore/stroke_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "7000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
            weekend: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
          },
        },
      ],
      address:
        "Infront of Royal Amar Green New Loha Mandi, Dewas Naka, Lasudia Mori, Indore",
      location: {
        latitude: "22.783007",
        longitude: "75.890368",
      },
      actualContact: "9009641488",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "STROKE-TURF-BY-OYE-INDORI",
  },
  {
    name: "24x7 Sports Club and Turf",
    domainKey: "24x7 Sports Club and Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/24*7SportsClub_turf_indore/24*7_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/24*7SportsClub_turf_indore/24*7_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/24*7SportsClub_turf_indore/24*7_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "8000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
          },
        },
      ],
      address:
        "Nipania Main Rd, behind Indian Oil Petrol Pump, near Ocean Park, Nipania, Indore",
      location: {
        latitude: "22.760488",
        longitude: "75.929677",
      },
      actualContact: "9577888881",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false,
    },
    userName: "24x7-Sports-Club-and-Turf",
  },
  {
    name: "Jai Malhar Sports Turf",
    domainKey: "Jai Malhar Sports Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/jaiMalhar_turf_indore/jaimalhar_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/jaiMalhar_turf_indore/jaimalhar_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/jaiMalhar_turf_indore/jaimalhar_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 900,
              },
            },
            weekend: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 900,
              },
            },
          },
        },
      ],
      address:
        "Jai malhar garden premises, 01 Shyam Nagar, Infront of kankeshwari dham, Shyam Nagar, Indore",
      location: {
        latitude: "22.758921",
        longitude: "75.869355",
      },
      actualContact: "9977200001",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "9 am",
        closingTime: "2 am",
        isAvailable24Hours: false,
      },
      isRainProtected: true,
      starRating: "4.0",
      hasCafe: false,
    },
    userName: "Jai-Malhar-Sports-Turf",
  },
  {
    name: "Sportzfy Turf",
    domainKey: "Sportzfy Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sportzfy_turf_indore/sportzfy_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sportzfy_turf_indore/sportzfy_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sportzfy_turf_indore/sportzfy_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 900,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 700,
              },
            },
            weekend: {
              regularHours: 1100,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 900,
              },
            },
          },
        },
      ],
      address: "562/3, RRCAT Road, Rangwasa Rd, Rau, Indore",
      location: { latitude: "22.653777", longitude: "75.803680" },
      actualContact: "7880087825",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "5.0",
      hasCafe: false,
    },
    userName: "Sportzfy-Turf",
  },
  {
    name: "NB Turf and Cafe",
    domainKey: "NB Turf and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/nb_turf_indore/nb_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/nb_turf_indore/nb_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/nb_turf_indore/nb_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "7200",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1100,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 600,
              },
            },
            weekend: {
              regularHours: 1100,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 600,
              },
            },
          },
        },
      ],
      address: "Reti Mandi, New Bijalpur, Indore",
      location: { latitude: "22.662272", longitude: "75.827161" },
      actualContact: "8421188009",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "9 am",
        closingTime: "10 pm",
        isAvailable24Hours: false,
      },
      isRainProtected: true,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "NB-Turf-and-Cafe",
  },
  {
    name: "Flick Turf and Cafe",
    domainKey: "Flick Turf and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/flick_turf_indore/flick_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/flick_turf_indore/flick_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/flick_turf_indore/flick_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "8000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1100,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 900,
              },
            },
          },
        },
      ],
      address: "Hukma Khedi, Indore",
      location: { latitude: "22.660304", longitude: "75.809563" },
      actualContact: "8602765650",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: true,
    },
    userName: "Flick-Turf-and-Cafe",
  },
  {
    name: "The Runway Turf",
    domainKey: "The Runway Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/runway_turf_indore/runway_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/runway_turf_indore/runway_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/runway_turf_indore/runway_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 900,
              },
            },
            weekend: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 900,
              },
            },
          },
        },
      ],
      address:
        "Airport Rd, behind Aerodrome police station, Shri Krishna Nagar, Kanyakubj Nagar, Indore",
      location: {
        latitude: "22.728414",
        longitude: "75.816954",
      },
      actualContact: "9131888236",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "9 am",
        closingTime: "12 am",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false,
    },
    userName: "The-Runway-Turf",
  },
  // Done
  {
    name: "APL TURF CAFE",
    domainKey: "APL TURF CAFE",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/apl_turf_indore/apl_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/apl_turf_indore/apl_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/apl_turf_indore/apl_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "4800",
          pitchDimensions: {length: 90, width: 56, height: 28},
          price: {
            weekday: {
              regularHours: 1100,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
          },
        },
        {
          area: "8000",
          price: {
            weekday: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1300,
              },
            },
            weekend: {
              regularHours: 1400,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400,
              },
            },
          },
        },
      ],
      address: "77/3/8, near IPS College, Bijalpur, Indore",
      location: { latitude: "22.655457", longitude: "75.823691" },
      actualContact: "8109938995",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "9 am",
        closingTime: "11:30 pm",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "3.0",
      hasCafe: true,
    },
    userName: "APL-TURF-CAFE",
  },
  {
    name: "HOTSHOT TURF N CAFEY",
    domainKey: "HOTSHOT TURF N CAFEY",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/hotshot_turf_indore/hotshot_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/hotshot_turf_indore/hotshot_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/hotshot_turf_indore/hotshot_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
            weekend: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
          },
        },
      ],
      address: "67, Tejpur Gadbadi, Indore",
      location: { latitude: "22.6846753", longitude: "75.8386885" },
      actualContact: "9522696333",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "HOTSHOT-TURF-N-CAFEY",
  },
  // Done
  {
    name: "GT Turf and Food Court",
    domainKey: "GT Turf and Food Court",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/gt_turf_indore/gt_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/gt_turf_indore/gt_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/gt_turf_indore/gt_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "14000",
          pitchDimensions: {length: 150, width: 77, height: 40},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
          },
        },
      ],
      address: "Kanadia Main Rd, behind HP Petrol Pump, Gokul Nagar, Indore",
      location: { latitude: "22.727536", longitude: "75.927076" },
      actualContact: "9643669735 / 7880039735",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: true,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: true,
    },
    userName: "GT-Turf-and-Food-Court",
  },
  // {
  //   name: "Maharana Sports Academy Indore",
  //   domainKey: "Maharana Sports Academy Indore",
  //   imageUrl: [
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/maharana_turf_indore/maharana_1.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/maharana_turf_indore/maharana_2.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/maharana_turf_indore/maharana_3.jpg",
  //   ],
  //   fields: [
  //     { label: "Contact", key: "actualContact", type: "link" },
  //     { label: "Contact", key: "displayContact", type: "link" },
  //     { label: "Partnership", key: "partnership", type: "boolean" },
  //     { label: "Area & Price", key: "areaPrice", type: "object" },
  //     { label: "Location", key: "location", type: "float" },
  //     { label: "Availability Time", key: "availabilityData", type: "object" },
  //     { label: "Rain Protected", key: "isRainProtected", type: "string" },
  //     { label: "Cafe", key: "hasCafe", type: "boolean" },
  //     { label: "Address", key: "address", type: "string" },
  //   ],
  //   data: {
  //     city: "Indore",
  //     // areaPrice: [{ area: "6000", price: { weekday: 1000, weekend: 1000 } }],
  //     areaPrice: [
  //       {
  //         area: "6000",
  //         price: {
  //           weekday: {
  //             regularHours: 1000,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 900
  //             }
  //           },
  //           weekend: {
  //             regularHours: 1000,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 900
  //             }
  //           },
  //         }
  //       }
  //     ],
  //     address:
  //       "Plot no. 185 & 211, Land lord estate, opp. Gate no. 2, near Shubh sampada colony, Samar Park Colony, Nipania, Indore",
  //     location: { latitude: "22.765451", longitude: "75.924110" },
  //     actualContact: "9826053372",
  //     displayContact: constants.BUISNESS_NUMBER,
  //     partnership: false,
  //     availabilityData: {
  //       openingTime: "6 am",
  //       closingTime: "11 pm",
  //       isAvailable24Hours: false,
  //     },
  //     isRainProtected: true,
  //     starRating: "5.0",
  //     hasCafe: true
  //   },
  // },
  // Done
  {
    name: "SP Turf and Cafe",
    domainKey: "SP Turf and Cafe",
    // bannerImageUrl:
    // "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sp_turf_indore/SP_Turf_BANNER+_web.png",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sp_turf_indore/sp_1.jpg"
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "8800",
          pitchDimensions: {length: 110, width: 80, height: 45},
          price: {
            weekday: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000
              }
            },
              weekend: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000
              }
            },
          }
        }
      ],
      address:
        "314/1/9 Tejpur Gadbadi, Bijalpur, Indore",
      location: { latitude: "22.673031", longitude: "75.847286" },
      actualContact: "9826677188",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: true,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: true
    },
    userName: "SP-Turf-and-Cafe"
  },
  {
    name: "SK Turf",
    domainKey: "SK Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sk_turf_indore/sk_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sk_turf_indore/sk_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sk_turf_indore/sk_3.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sk_turf_indore/sk_4.jpg"
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "5500",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 700
              }
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800
              }
            },
          }
        }
      ],
      address:
        "7, Gram Baank, near TVs KS moter, Noorani Nagar, Indore",
      location: { latitude: "22.703144", longitude: "75.8089789" },
      actualContact: " 9755505470",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: true
    },
    userName: "SK-Turf"
  },
  {
    name: "LBW Turf and Cafe",
    domainKey: "LBW Turf and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/lbw_turf_indore/lbw_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/lbw_turf_indore/lbw_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/lbw_turf_indore/lbw_3.jpg"
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "7000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1250,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900
              // }
            },
            weekend: {
              regularHours: 1250,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900
              // }
            },
          }
        },
        {
          area: "7000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1250,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900
              // }
            },
            weekend: {
              regularHours: 1250,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900
              // }
            },
          }
        }
      ],
      address:
        "C-2 Empire Estate, By Pass Road, Opposite Sahara City, Indore",
      location: { latitude: "22.689363", longitude: "75.9233884" },
      actualContact: "8989599996",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: true
    },
    userName: "LBW-Turf-and-Cafe"
  },
  {
    name: "Freehit Cricket Turf & Cafe",
    domainKey: "Freehit Cricket Turf & Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/freeHit_turf_indore/freehit_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/freeHit_turf_indore/freehit_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/freeHit_turf_indore/freehit_3.jpg"
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "19000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1500,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900
              // }
            },
            weekend: {
              regularHours: 1600,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 900
              // }
            },
          }
        },
      ],
      address:
        "Freehit, near Somya Vehicles Mahindra Showroom, Baap Jee Nagar, Indore",
      location: { latitude: "22.767476", longitude: "75.900878" },
      actualContact: "9630097313",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "9 am",
        closingTime: "2 am",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "4.5",
      hasCafe: true
    },
    userName: "Freehit-Cricket-Turf-and-Cafe"
  },
  // Done
  {
    name: "Star Turf & Cafe",
    domainKey: "Star Turf & Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/star_turf_indore/star_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/star_turf_indore/star_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/star_turf_indore/star_3.jpg"
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "7500",
          pitchDimensions: {length: 70, width: 110, height: 40},
          price: {
            weekday: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200
              }
            },
            weekend: {
              regularHours: 1400,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1000
              // }
            },
          }
        }
      ],
      address:
        "Choithram Hospital, Road, Manik Bagh Rd, behind Mathura Mahal, Choitram compound, Indore",
      location: { latitude: "22.685759", longitude: "75.854327" },
      actualContact: "9039044432",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: true
    },
    userName: "Star-Turf-and-Cafe"
  },
  {
    name: "Reverso Turf",
    domainKey: "Reverso Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/reverso_turf_indore/reverso_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/reverso_turf_indore/reverso_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/reverso_turf_indore/reverso_3.jpg"
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "5000",
          pitchDimensions: {length: 70, width: 110, height: 40},
          price: {
            weekday: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1000
              // }
            },
            weekend: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1000
              // }
            },
          }
        }
      ],
      address:
        "The Hub, near Prestige Institute of Management and Research UG Campus, Scheme Number 78, Part II, Scheme 78, Indore",
      location: { latitude: "22.7621464", longitude: "75.8867609" },
      actualContact: "7770955558",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "5.0",
      hasCafe: true
    },
    userName: "Reverso-Turf"
  },
  {
    name: "Regional Turfyard",
    domainKey: "Regional Turfyard",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/regional_turf_indore/regional_turfyard_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/regional_turf_indore/regional_turfyard_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/regional_turf_indore/regional_turfyard_3.jpg"
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "10000",
          pitchDimensions: {length: 70, width: 110, height: 40},
          price: {
            weekday: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000
              }
            },
            weekend: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000
              }
            },
          }
        }
      ],
      address:
        "near, Regional Park Rd, Kasturba Gram, Indore",
      location: { latitude: "22.673440", longitude: "75.855851" },
      actualContact: "8889888203",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "4.5",
      hasCafe: false
    },
    userName: "Regional-Turfyard"
  },
  // {
  //   name: "Players Turf",
  //   domainKey: "Players Turf",
  //   imageUrl: [
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/players_turf_indore/players_1.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/players_turf_indore/players_2.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/players_turf_indore/players_3.jpg"
  //   ],
  //   fields: [
  //     { label: "Contact", key: "actualContact", type: "link" },
  //     { label: "Contact", key: "displayContact", type: "link" },
  //     { label: "Partnership", key: "partnership", type: "boolean" },
  //     { label: "Area & Price", key: "areaPrice", type: "object" },
  //     { label: "Location", key: "location", type: "float" },
  //     { label: "Availability Time", key: "availabilityData", type: "object" },
  //     { label: "Rain Protected", key: "isRainProtected", type: "string" },
  //     { label: "Cafe", key: "hasCafe", type: "boolean" },
  //     { label: "Address", key: "address", type: "string" },
  //   ],
  //   data: {
  //     city: "Indore",
  //     areaPrice: [
  //       {
  //         area: "8800",
            // pitchDimensions: {length: 70, width: 110, height: 40},
  //         price: {
  //           weekday: {
  //             regularHours: 1300,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 1000
  //             }
  //           },
  //           weekend: {
  //             regularHours: 1300,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 1000
  //             }
  //           },
  //         }
  //       }
  //     ],
  //     address:
  //       "opp Eicher Showroom, Vrindavan Palace Colony, Nipania, Indore",
  //     location: { latitude: "22.770104", longitude: "75.910460" },
  //     actualContact: "9926100900",
  //     displayContact: constants.BUISNESS_NUMBER,
  //     partnership: false,
  //     availabilityData: {
  //       openingTime: "6 am",
  //       closingTime: "12 am",
  //       isAvailable24Hours: false,
  //     },
  //     isRainProtected: true,
  //     starRating: "4.0",
  //     hasCafe: true
  //   },
  //   userName: "Players-Turf"
  // },
  {
    name: "Greenland Turf and Cafe",
    domainKey: "Greenland Turf and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/greenland_turf_indore/greenland_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/greenland_turf_indore/greenland_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/greenland_turf_indore/greenland_3.jpeg"
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 70, width: 110, height: 40},
          price: {
            weekday: {
              regularHours: 700,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 500
              }
            },
            weekend: {
              regularHours: 800,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 600
              }
            },
          }
        }
      ],
      address:
        "Prem bandhan Garden, 1393, Kanadia Main Rd, near Saif Nursery, Bicholi Mardana, Indore",
      location: { latitude: "22.719673", longitude: "75.912919" },
      actualContact: "8103777874",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: true,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.5",
      hasCafe: true
    },
    userName: "Greenland-Turf-and-Cafe"
  },
  {
    name: "The Homeground Turf and Cafe",
    domainKey: "The Homeground Turf and Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/homeground_turf_indore/homeground_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/homeground_turf_indore/homeground_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/homeground_turf_indore/homeground_3.jpg"
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "6500",
          pitchDimensions: {length: 70, width: 110, height: 40},
          price: {
            weekday: {
              regularHours: 1100,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200
              }
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400
              }
            },
          }
        }
      ],
      address:
        "Chanda prabhu compound, Dinesh Nagar, Palda, Indore",
      location: { latitude: "22.679664", longitude: "75.899111" },
      actualContact: "9926699896",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "4.5",
      hasCafe: false
    },
    userName: "The-Homeground-Turf-and-Cafe"
  },
  {
    name: "ITC Indori Turf & Cafe",
    domainKey: "ITC Indori Turf & Cafe",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/itc_turf_indore/itc_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/itc_turf_indore/itc_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/itc_turf_indore/itc_3.jpg"
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "7000",
          pitchDimensions: {length: 70, width: 110, height: 40},
          price: {
            weekday: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200
              }
            },
            weekend: {
              regularHours: 1400,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200
              }
            },
          }
        }
      ],
      address:
        "CAT Rd, RRCAT, Indore",
      location: { latitude: "22.672053", longitude: "75.815853" },
      actualContact: "9826038420",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "4.0",
      hasCafe: true
    },
    userName: "ITC-Indori-Turf-and-Cafe"
  },
  {
    name: "Desi Turf & Food zone",
    domainKey: "Desi Turf & Food zone",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/desi_turf_indore/desi_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/desi_turf_indore/desi_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/desi_turf_indore/desi_3.jpg"
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "12000",
          pitchDimensions: {length: 70, width: 110, height: 40},
          price: {
            weekday: {
              regularHours: 800,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1000
              // }
            },
            weekend: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1000
              // }
            },
          }
        }
      ],
      address:
        "shri, Krishna Ave Phase 3, Shivdham Colony, Limbodi, Indore",
      location: { latitude: "22.662350", longitude: "75.893893" },
      actualContact: "8269669579",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "4.0",
      hasCafe: true
    },
    userName: "Desi-Turf-and-Food-zone"
  },
  {
    name: "One Tip Turf",
    domainKey: "One Tip Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/one_tip_turf_indore/one_tip_1.png",
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          area: "8000",
          pitchDimensions: {length: 70, width: 110, height: 40},
          price: {
            weekday: {
              regularHours: 1200,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1000
              // }
            },
            weekend: {
              regularHours: 1400,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1000
              // }
            },
          }
        }
      ],
      address:
        "Khajrana Square, near Powerhouse, Scheme 94, Indore",
      location: { latitude: "22.730738", longitude: "75.902339" },
      actualContact: "8269993011 / 8269996011",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "4 am",
        closingTime: "1 am",
        isAvailable24Hours: false
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: true
    },
    userName: "One-Tip-Turf"
  },
  {
    name: "Sports Villa",
    domainKey: "Sports Villa",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sports_villa_turf_indore/sportsvilla_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sports_villa_turf_indore/sportsvilla_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/sports_villa_turf_indore/sportsvilla_3.jpg",
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          "area": "10000",
          "price": {
            "weekday": {
              "regularHours": 1200,
              "happyHours": {
                "range": "6:00AM-6:00PM",
                "price": 1100
              }
            },
            "weekend": {
              "regularHours": 1300,
              "happyHours": {
                "range": "6:00AM-6:00PM",
                "price": 1300
              }
            },
          }
        }
      ],
      address:
        "Hawa Bangala Rd, near RRCAT, square, RRCAT, Indore",
      location: { latitude: "22.672095", longitude: "75.809902" },
      actualContact: "+919893333240",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true
      },
      isRainProtected: true,
      starRating: "4.5",
      hasCafe: true
    },
    userName: "Sports-Villa"
  },
  {
    name: "Futsal Turf",
    domainKey: "Futsal Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/futsal_turf_indore/futsal_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/futsal_turf_indore/futsal_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/futsal_turf_indore/futsal_3.jpg",
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          "area": "6500",
          "price": {
            "weekday": {
              "regularHours": 1000,
              "happyHours": {
                "range": "6:00AM-6:00PM",
                "price": 800
              }
            },
            "weekend": {
              "regularHours": 1000,
              "happyHours": {
                "range": "10:00AM-6:00PM",
                "price": 800
              }
            },
          }
        }
      ],
      address:
        "Empire Wildflower, Bypass Near Millennium School, Indore",
      location: { latitude: "22.658811045484168", longitude: "75.91213523606318" },
      actualContact: "+919584956667",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false
    },
    userName: "Futsal-One"
  },
  {
    name: "Nightowl Turf",
    domainKey: "Nightowl Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/nightowl_turf_indore/nightowl_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/nightowl_turf_indore/nightowl_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/nightowl_turf_indore/nightowl_3.jpg",
    ],
    fields: [
      { label: "Contact", key: "actualContact", type: "link" },
      { label: "Contact", key: "displayContact", type: "link" },
      { label: "Partnership", key: "partnership", type: "boolean" },
      { label: "Area & Price", key: "areaPrice", type: "object" },
      { label: "Location", key: "location", type: "float" },
      { label: "Availability Time", key: "availabilityData", type: "object" },
      { label: "Rain Protected", key: "isRainProtected", type: "string" },
      { label: "Cafe", key: "hasCafe", type: "boolean" },
      { label: "Address", key: "address", type: "string" },
    ],
    data: {
      city: "Indore",
      areaPrice: [
        {
          "area": "8000",
          "price": {
            "weekday": {
              "regularHours": 1300,
              "happyHours": {
                "range": "6:00AM-8:00PM",
                "price": 1100
              }
            },
            "weekend": {
              "regularHours": 1300,
              "happyHours": {
                "range": "6:00AM-6:00PM",
                "price": 1300
              }
            },
          }
        }
      ],
      address:  "Silicon city,Indore",
      location: { latitude: "22.648089", longitude: "75.823238" },
      actualContact: "9009688607 / 9753351337",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true
      },
      isRainProtected: true,
      starRating: "4.5",
      hasCafe: true
    },
    userName: "Nightowl-Turf"
  },
  // {
  //   name: "",
  //   domainKey: "",
  //   imageUrl: [
  //     "",
  //   ],
  //   fields: [
  //     { label: "Contact", key: "actualContact", type: "link" },
  //     { label: "Contact", key: "displayContact", type: "link" },
  //     { label: "Partnership", key: "partnership", type: "boolean" },
  //     { label: "Area & Price", key: "areaPrice", type: "object" },
  //     { label: "Location", key: "location", type: "float" },
  //     { label: "Availability Time", key: "availabilityData", type: "object" },
  //     { label: "Rain Protected", key: "isRainProtected", type: "string" },
  //     { label: "Cafe", key: "hasCafe", type: "boolean" },
  //     { label: "Address", key: "address", type: "string" },
  //   ],
  //   data: {
  //     city: "Indore",
  //     areaPrice: [
  //       {
  //         "area": "",
  //         "price": {
  //           "weekday": {
  //             "regularHours": ,
  //             "happyHours": {
  //               "range": "6:00AM-6:00PM",
  //               "price": 
  //             }
  //           },
  //           "weekend": {
  //             "regularHours": ,
  //             "happyHours": {
  //               "range": "6:00AM-6:00PM",
  //               "price": 
  //             }
  //           },
  //         }
  //       }
  //     ],
  //     address:
  //       "",
  //     location: { latitude: "", longitude: "" },
  //     actualContact: "",
  //     displayContact: constants.BUISNESS_NUMBER,
  //     partnership: false,
  //     availabilityData: {
  //       openingTime: "",
  //       closingTime: "",
  //       isAvailable24Hours: true
  //     },
  //     isRainProtected: true,
  //     starRating: "4.5",
  //     hasCafe: true
  //   },
  //   userName: "Sports-Villa"
  // },
  // {
  //   name: "RR Sports and Amenities",
  //   domainKey: "RR Sports and Amenities",
  //   imageUrl: [
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/rr_turf_indore/rr_1.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/rr_turf_indore/rr_2.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/IndoreTurfs/rr_turf_indore/rr_3.jpg"
  //   ],
  //   fields: [
  //     { label: "Contact", key: "actualContact", type: "link" },
  //     { label: "Contact", key: "displayContact", type: "link" },
  //     { label: "Partnership", key: "partnership", type: "boolean" },
  //     { label: "Area & Price", key: "areaPrice", type: "object" },
  //     { label: "Location", key: "location", type: "float" },
  //     { label: "Availability Time", key: "availabilityData", type: "object" },
  //     { label: "Rain Protected", key: "isRainProtected", type: "string" },
  //     { label: "Cafe", key: "hasCafe", type: "boolean" },
  //     { label: "Address", key: "address", type: "string" },
  //   ],
  //   data: {
  //     city: "Indore",
  //     areaPrice: [
  //       {
  //         area: "6250",
  //         price: {
  //           weekday: {
  //             regularHours: 1300,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 1000
  //             }
  //           },
  //           weekend: {
  //             regularHours: 1300,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 1000
  //             }
  //           },
  //         }
  //       }
  //     ],
  //     address:
  //       "Pearl leaf, Super Corridor Rd, near Infosys, near Symbiosis College, Bada Bangarda, Indore",
  //     location: { latitude: "22.765870", longitude: "75.795792" },
  //     actualContact: "9111144062",
  //     displayContact: constants.BUISNESS_NUMBER,
  //     partnership: false,
  //     availabilityData: {
  //       openingTime: "",
  //       closingTime: "",
  //       isAvailable24Hours: true
  //     },
  //     isRainProtected: true,
  //     starRating: "4.0",
  //     hasCafe: true
  //   },
  //   userName: "RR-Sports-and-Amenities"
  // },
  // Dewas-turfs
  {
    name: "Town Turf",
    domainKey: "Town Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/DewasTurfs/town_turf_dewas/town_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/DewasTurfs/town_turf_dewas/town_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/DewasTurfs/town_turf_dewas/town_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Dewas",
      areaPrice: [
        {
          area: "4000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 800,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 600,
              },
            },
            weekend: {
              regularHours: 800,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 600,
              },
            },
          },
        },
      ],
      address: "Mina Bazar, Bhopal Square, Adarsh Nagar, Dewas",
      location: {
        latitude: "22.96853 ",
        longitude: "76.06514",
      },
      actualContact: "8602233360",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false,
    },
    userName: "Town-Turf",
  },
  {
    name: "Nirlip Sports & Fitness Turf",
    domainKey: "Nirlip Sports and Fitness Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/DewasTurfs/nirlip_turf_dewas/nirlip_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/DewasTurfs/nirlip_turf_dewas/nirlip_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/DewasTurfs/nirlip_turf_dewas/nirlip_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Dewas",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800,
              },
            },
            weekend: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800,
              },
            },
          },
        },
        {
          area: "12100",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1800,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1600,
              },
            },
            weekend: {
              regularHours: 1800,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1600,
              },
            },
          },
        },
      ],
      address: "Jail Rd, Dewas",
      location: {
        latitude: "22.95623 ",
        longitude: "76.08692",
      },
      actualContact: "9713219444",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "Nirlip-Sports-and-Fitness-Turf",
  },
  // Bhopal-turfs
  {
    name: "Turf Club 07",
    domainKey: "Turf Club 07",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/club07_turf_bhopal/club07_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/club07_turf_bhopal/club07_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/club07_turf_bhopal/club07_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Bhopal",
      areaPrice: [
        {
          area: "5000",
          pitchDimensions: {length: 90, width: 55, height: 30},
          price: {
            weekday: {
              regularHours: 600,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 500,
              },
            },
            weekend: {
              regularHours: 600,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 600,
              },
            },
          },
        },
      ],
      address: "Bhauri jod, behind Status Resort, Bhopal",
      location: { latitude: "23.264408", longitude: "77.298815" },
      actualContact: "8160250047",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: true,
    },
    userName: "Turf-Club-07",
  },
  {
    name: "Turf Score 100",
    domainKey: "Turf Score 100",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/score100_turf_bhopal/score100_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/score100_turf_bhopal/score100_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/score100_turf_bhopal/score100_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Bhopal",
      areaPrice: [
        {
          area: "5000",
          pitchDimensions: {length: 90, width: 60, height: 35},
          price: {
            weekday: {
              regularHours: 600,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 500,
              },
            },
            weekend: {
              regularHours: 600,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 600,
              },
            },
          },
        },
      ],
      address: "Near aakash garden, Indore bhopal highway, Bairagarh, Bhopal",
      location: { latitude: "23.267103", longitude: "77.323626" },
      actualContact: "8349295531",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "5.0",
      hasCafe: true,
    },
    userName: "Turf-Score-100",
  },
  {
    name: "TURF ZONE",
    domainKey: "TURF ZONE",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/zone_turf_bhopal/zone_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/zone_turf_bhopal/zone_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/zone_turf_bhopal/zone_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Bhopal",
      areaPrice: [
        {
          area: "5000",
          pitchDimensions: {length: 100, width: 50, height: 30},
          price: {
            weekday: {
              regularHours: 600,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 600,
              },
            },
            weekend: {
              regularHours: 600,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 600,
              },
            },
          },
        },
      ],
      address:
        "DK- 5, Danish Kunj Kolar Rd, Opposite Virasha Heights, Danish Kunj, Kolar Rd, Bhopal",
      location: {
        latitude: "23.169450",
        longitude: "77.431082",
      },
      actualContact: "9993427480",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "8 am",
        closingTime: "11 pm",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "5.0",
      hasCafe: true,
    },
    userName: "TURF-ZONE",
  },
  {
    name: "Sai Sports Academy Turf Jatkheri",
    domainKey: "Sai Sports Academy Turf Jatkheri",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/sai_sports_turf_bhopal/sai_sports_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/sai_sports_turf_bhopal/sai_sports_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/sai_sports_turf_bhopal/sai_sports_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Bhopal",
      areaPrice: [
        {
          area: "6500",
          pitchDimensions: {length: 100, width: 60, height: 25},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
            weekend: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
          },
        },
      ],
      address: " near Nikhil nestle, Shri Rameshwaram, Jatkhedi, Bhopal",
      location: { latitude: "23.178306", longitude: "77.468457" },
      actualContact: "9111047333",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "8 am",
        closingTime: "12 am",
        isAvailable24Hours: false,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: false,
    },
    userName: "Sai-Sports-Academy-Turf-Jatkheri",
  },
  {
    name: "The Sports Wing Bhopal Turf And Futsal Arena",
    domainKey: "The Sports Wing Bhopal Turf And Futsal Arena",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/sports_wing_turf_bhopal/sportsWing_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/sports_wing_turf_bhopal/sportsWing_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/sports_wing_turf_bhopal/sportsWing_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Bhopal",
      areaPrice: [
        {
          area: "7200",
          pitchDimensions: {length: 120, width: 60, height: 30},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
          },
        },
      ],
      address:
        "Plot 9/2/7, in front of Aura Mall, near Rajeev Gandhi College, E-8, Trilanga, Bhopal",
      location: {
        latitude: "23.188349",
        longitude: "77.432361",
      },
      actualContact: "9827534449",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "4.0",
      hasCafe: true,
    },
    userName: "The-Sports-Wing-Bhopal-Turf-And-Futsal-Arena",
  },
  {
    name: "Strike Gaming Zone",
    domainKey: "Strike Gaming Zone",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/strike_game_turf_bhopal/strike_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/strike_game_turf_bhopal/strike_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/BhopalTurfs/strike_game_turf_bhopal/strike_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Bhopal",
      areaPrice: [
        {
          area: "7000",
          pitchDimensions: {length: 115, width: 60, height: 30},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
            weekend: {
              regularHours: 1500,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1500,
              },
            },
          },
        },
      ],
      address:
        "Sakshi Dhabha, Kerwa road, near Countryside meadows, National Law Institute University, Bhopal",
      location: {
        latitude: "23.202561",
        longitude: "77.370076",
      },
      actualContact: "9692489692",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: true,
    },
    userName: "Strike-Gaming-Zone",
  },
  // Ujjain-turfs
  {
    name: "Fun Run Arena",
    domainKey: "Fun Run Arena",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/fun_run_arena_ujjain/fun_arena_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/fun_run_arena_ujjain/fun_arena_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/fun_run_arena_ujjain/fun_arena_3.png",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Ujjain",
      areaPrice: [
        {
          area: "2500",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1000,
              // },
            },
            weekend: {
              regularHours: 1500,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1500,
              // },
            },
          },
        },
      ],
      address: "Gayatri Ave, Abhilasha Colony, Ujjain",
      location: { 
        latitude: "23.135528",
        longitude: "75.826467"
      },
      actualContact: "7000925075",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false
    },
    userName: "Fun-Run-Arena"
  },
  // {
  //   name: "The Greenescape Turf",
  //   domainKey: "The Greenescape Turf",
  //   imageUrl: [
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/greenscape_turf_ujjain/greenscape_1.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/greenscape_turf_ujjain/greenscape_2.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/greenscape_turf_ujjain/greenscape_3.jpg",
  //   ],
  //   fields: [
  //     {
  //       label: "Contact",
  //       key: "actualContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Contact",
  //       key: "displayContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Partnership",
  //       key: "partnership",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Area & Price",
  //       key: "areaPrice",
  //       type: "object",
  //     },
  //     {
  //       label: "Location",
  //       key: "location",
  //       type: "float",
  //     },
  //     {
  //       label: "Availability Time",
  //       key: "availabilityData",
  //       type: "object",
  //     },
  //     {
  //       label: "Rain Protected",
  //       key: "isRainProtected",
  //       type: "string",
  //     },
  //     {
  //       label: "Cafe",
  //       key: "hasCafe",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Address",
  //       key: "address",
  //       type: "string",
  //     },
  //   ],
  //   data: {
  //     city: "Ujjain",
  //     areaPrice: [
  //       {
  //         area: "6000",
  //         price: {
  //           weekday: {
  //             regularHours: 800,
  //             // happyHours: {
  //             //   range: "6:00AM-6:00PM",
  //             //   price: 1000,
  //             // },
  //           },
  //           weekend: {
  //             regularHours: 800,
  //             // happyHours: {
  //             //   range: "6:00AM-6:00PM",
  //             //   price: 1500,
  //             // },
  //           },
  //         },
  //       },
  //     ],
  //     address: "Pawapuri Colony, Malanwasa, Ujjain",
  //     location: {
  //       latitude: "23.137108",
  //       longitude: "75.798573"
  //     },
  //     actualContact: "7898584869",
  //     displayContact: constants.BUISNESS_NUMBER,
  //     partnership: false,
  //     availabilityData: {
  //       openingTime: "",
  //       closingTime: "",
  //       isAvailable24Hours: true,
  //     },
  //     isRainProtected: false,
  //     starRating: "4.0",
  //     hasCafe: false
  //   },
  //   userName: "The-Greenescape-Turf"
  // },
  {
    name: "Battle Ground The Turf",
    domainKey: "Battle Ground The Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/battleground_turf_ujjain/battleground_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/battleground_turf_ujjain/battleground_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/battleground_turf_ujjain/battleground_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Ujjain",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 900,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1000,
              // },
            },
            weekend: {
              regularHours: 900,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1500,
              // },
            },
          },
        },
      ],
      address: "22, hariom Toll Kanta, bafna park, maxi road, near St. Paul School, Bapna Park Colony, Ujjain",
      location: {
        latitude: "23.197553",
        longitude: "75.793309"
      },
      actualContact: "9754572584",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false
    },
    userName: "Battle-Ground-The-Turf",
  },
  {
    name: "The 7S Turf",
    domainKey: "The 7S Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/7s_turf_ujjain/7s_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/7s_turf_ujjain/7s_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/7s_turf_ujjain/7s_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Ujjain",
      areaPrice: [
        {
          area: "7350",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 800,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1000,
              // },
            },
            weekend: {
              regularHours: 800,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1500,
              // },
            },
          },
        },
      ],
      address: "Sawra khedi, Ujjain",
      location: {
        latitude: "23.167110",
        longitude: "75.758708"
      },
      actualContact: "7089344364",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false
    },
    userName: "The-7S-Turf",
  },
  {
    name: "Tuff The Turf",
    domainKey: "Tuff The Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/tuff_turf_ujjain/tuff_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/tuff_turf_ujjain/tuff_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/UjjainTurfs/tuff_turf_ujjain/tuff_3.png",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Ujjain",
      areaPrice: [
        {
          area: "7000",
          pitchDimensions: {length: 45, width: 56, height: 35},
          price: {
            weekday: {
              regularHours: 500,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1000,
              // },
            },
            weekend: {
              regularHours: 500,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1500,
              // },
            },
          },
        },
      ],
      address: "Agar Rd, Ujjain, Hakkanipura",
      location: {
        latitude: "23.221859",
        longitude: "75.798909"
      },
      actualContact: "9131000213",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "10 am",
        closingTime: "11:30 pm",
        isAvailable24Hours: false,
      },
      isRainProtected: true,
      starRating: "4.0",
      hasCafe: false
    },
    userName: "Tuff-The-Turf"
  },
  // Pune Turfs
  {
    name: "GreenBox Turfs",
    domainKey: "GreenBox Turfs",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/greenbox_turf_pune/greenbox_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/greenbox_turf_pune/greenbox_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/greenbox_turf_pune/greenbox_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
        {
          area: "12000",
          pitchDimensions: {length: 133, width: 90, height: 25},
          price: {
            weekday: {
              regularHours: 2000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1000,
              // },
            },
            weekend: {
              regularHours: 2000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1500,
              // },
            },
          },
        },
      ],
      address: "Bhandarkar Institute NO. 812, Chiplunkar Road, ILS, Law College Rd, Shivajinagar, Pune, Maharashtra",
      location: {
        latitude: "18.518383",
        longitude: "73.829980"
      },
      actualContact: "8793041761",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "6 am",
        closingTime: "11:00 pm",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false
    },
    userName: "GreenBox-Turfs"
  },
  {
    name: "Game On Football Turf",
    domainKey: "Game On Football Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/gameon_turf_pune/gameon_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/gameon_turf_pune/gameon_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/gameon_turf_pune/gameon_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 100, width: 60, height: 20},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800,
              },
            },
            weekend: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
          },
        },
      ],
      address: "New 100 Ft, DP Rd, Karve Nagar, Pune, Maharashtra",
      location: {
        latitude: "18.482840",
        longitude: "73.817131"
      },
      actualContact: "7507000111",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "6 am",
        closingTime: "12:00 am",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: true
    },
    userName: "Game-On-Football-Turf"
  },
  {
    name: "Turf 10",
    domainKey: "Turf 10",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/turf_10_pune/turf10_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/turf_10_pune/turf10_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/turf_10_pune/turf10_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
        {
          area: "5000",
          pitchDimensions: {length: 100, width: 50, height: 25},
          price: {
            weekday: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 800,
              // },
            },
            weekend: {
              regularHours: 1000,
              // happyHours: {
              //   range: "6:00AM-6:00PM",
              //   price: 1200,
              // },
            },
          },
        },
      ],
      address: "Aundh - Baner Link Rd, Aundh, Pune, Maharashtra",
      location: {
        latitude: "18.564563",
        longitude: "73.798130"
      },
      actualContact: "8149933378",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false
    },
    userName: "Turf-10"
  },
  {
    name: "The Turf Ground",
    domainKey: "The Turf Ground",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/turf_ground_pune/turf_ground_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/turf_ground_pune/turf_ground_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/turf_ground_pune/turf_ground_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
        {
          area: "10000",
          pitchDimensions: {length: 125, width: 80, height: 40},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800,
              },
            },
            weekend: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
          },
        },
      ],
      address: "Sr no169, Shinde Farm, near Mahatma soc, Jijai Nagar, Kothrud, Pune, Maharashtra",
      location: {
        latitude: "18.502157",
        longitude: "73.793563"
      },
      actualContact: "9960983883",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "6 am",
        closingTime: "12:00 am",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "4.5",
      hasCafe: false
    },
    userName: "The-Turf-Ground"
  },
  // {
  //   name: "The Arena 5A Side Football Turf",
  //   domainKey: "The Arena 5A Side Football Turf",
  //   imageUrl: [
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/arena_5a_turf_pune/arena_5a_1.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/arena_5a_turf_pune/arena_5a_2.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/arena_5a_turf_pune/arena_5a_3.jpg",
  //   ],
  //   fields: [
  //     {
  //       label: "Contact",
  //       key: "actualContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Contact",
  //       key: "displayContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Partnership",
  //       key: "partnership",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Area & Price",
  //       key: "areaPrice",
  //       type: "object",
  //     },
  //     {
  //       label: "Location",
  //       key: "location",
  //       type: "float",
  //     },
  //     {
  //       label: "Availability Time",
  //       key: "availabilityData",
  //       type: "object",
  //     },
  //     {
  //       label: "Rain Protected",
  //       key: "isRainProtected",
  //       type: "string",
  //     },
  //     {
  //       label: "Cafe",
  //       key: "hasCafe",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Address",
  //       key: "address",
  //       type: "string",
  //     },
  //   ],
  //   data: {
  //     city: "Pune",
  //     areaPrice: [
  //       {
  //         area: "7000",
  //         price: {
  //           weekday: {
  //             regularHours: 1200,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 800,
  //             },
  //           },
  //           weekend: {
  //             regularHours: 1300,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 1200,
  //             },
  //           },
  //         },
  //       },
  //     ],
  //     address: "Vidyanchal School Sr no 259 Balaji park 411007, behind Seasons hotel, Sanewadi, Aundh, Pune, Maharashtra",
  //     location: {
  //       latitude: "18.555084",
  //       longitude: "73.802814"
  //     },
  //     actualContact: "9922143210",
  //     displayContact: constants.BUISNESS_NUMBER,
  //     partnership: false,
  //     availabilityData: {
  //       openingTime: "5 am",
  //       closingTime: "1:00 am",
  //       isAvailable24Hours: false,
  //     },
  //     isRainProtected: false,
  //     starRating: "4.5",
  //     hasCafe: false
  //   },
  //   userName: "The-Arena-5A-Side-Football-Turf"
  // },
  // {
  //   name: "Pro Turf Cricket & Football Turfs",
  //   domainKey: "Pro Turf Cricket & Football Turfs",
  //   imageUrl: [
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/pro_turf_pune/pro_turf_1.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/pro_turf_pune/pro_turf_2.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/pro_turf_pune/pro_turf_3.jpg",
  //   ],
  //   fields: [
  //     {
  //       label: "Contact",
  //       key: "actualContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Contact",
  //       key: "displayContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Partnership",
  //       key: "partnership",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Area & Price",
  //       key: "areaPrice",
  //       type: "object",
  //     },
  //     {
  //       label: "Location",
  //       key: "location",
  //       type: "float",
  //     },
  //     {
  //       label: "Availability Time",
  //       key: "availabilityData",
  //       type: "object",
  //     },
  //     {
  //       label: "Rain Protected",
  //       key: "isRainProtected",
  //       type: "string",
  //     },
  //     {
  //       label: "Cafe",
  //       key: "hasCafe",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Address",
  //       key: "address",
  //       type: "string",
  //     },
  //   ],
  //   data: {
  //     city: "Pune",
  //     areaPrice: [
  //       {
  //         area: "7000",
  //         price: {
  //           weekday: {
  //             regularHours: 1200,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 800,
  //             },
  //           },
  //           weekend: {
  //             regularHours: 1300,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 1200,
  //             },
  //           },
  //         },
  //       },
  //     ],
  //     address: "Cult Hotel, restaurant, Road, behind Nyati Evolve, in front of Nyati Evolve Road, Somnath Nagar, Mundhwa Industrial Area, Mundhwa, Pune, Maharashtra",
  //     location: {
  //       latitude: "18.524766",
  //       longitude: "73.925562"
  //     },
  //     actualContact: "9763270097",
  //     displayContact: constants.BUISNESS_NUMBER,
  //     partnership: false,
  //     availabilityData: {
  //       openingTime: "5 am",
  //       closingTime: "12:00 am",
  //       isAvailable24Hours: false,
  //     },
  //     isRainProtected: false,
  //     starRating: "5.0",
  //     hasCafe: false
  //   },
  //   userName: "Pro-Turf-Cricket-and-Football-Turfs"
  // },
  // {
  //   name: "Mundhwa Turf Ground Cricket & Football",
  //   domainKey: "Mundhwa Turf Ground Cricket & Football",
  //   imageUrl: [
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/mundhwa_turf_pune/mundhwa_1.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/mundhwa_turf_pune/mundhwa_2.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/mundhwa_turf_pune/mundhwa_3.jpg",
  //   ],
  //   fields: [
  //     {
  //       label: "Contact",
  //       key: "actualContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Contact",
  //       key: "displayContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Partnership",
  //       key: "partnership",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Area & Price",
  //       key: "areaPrice",
  //       type: "object",
  //     },
  //     {
  //       label: "Location",
  //       key: "location",
  //       type: "float",
  //     },
  //     {
  //       label: "Availability Time",
  //       key: "availabilityData",
  //       type: "object",
  //     },
  //     {
  //       label: "Rain Protected",
  //       key: "isRainProtected",
  //       type: "string",
  //     },
  //     {
  //       label: "Cafe",
  //       key: "hasCafe",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Address",
  //       key: "address",
  //       type: "string",
  //     },
  //   ],
  //   data: {
  //     city: "Pune",
  //     areaPrice: [
  //       {
  //         area: "7000",
  //         price: {
  //           weekday: {
  //             regularHours: 1200,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 800,
  //             },
  //           },
  //           weekend: {
  //             regularHours: 1300,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 1200,
  //             },
  //           },
  //         },
  //       },
  //     ],
  //     address: "BG Shirke Rd, Somnath Nagar, Kirtane Baug, Mundhwa Industrial Area, Mundhwa, Pune, Maharashtra",
  //     location: {
  //       latitude: "18.523511",
  //       longitude: "73.930316"
  //     },
  //     actualContact: "9130308809",
  //     displayContact: constants.BUISNESS_NUMBER,
  //     partnership: false,
  //     availabilityData: {
  //       openingTime: "5 am",
  //       closingTime: "12 am",
  //       isAvailable24Hours: false,
  //     },
  //     isRainProtected: false,
  //     starRating: "4.0",
  //     hasCafe: false
  //   },
  //   userName: "Mundhwa-Turf-Ground-Cricket-and-Football"
  // },
  // {
  //   name: "Runbhumi Twin Turf",
  //   domainKey: "Runbhumi Twin Turf",
  //   imageUrl: [
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/runbhumi+_turf_pune/runbhumi_1.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/runbhumi+_turf_pune/runbhumi_2.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/runbhumi+_turf_pune/runbhumi_3.jpg",
  //   ],
  //   fields: [
  //     {
  //       label: "Contact",
  //       key: "actualContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Contact",
  //       key: "displayContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Partnership",
  //       key: "partnership",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Area & Price",
  //       key: "areaPrice",
  //       type: "object",
  //     },
  //     {
  //       label: "Location",
  //       key: "location",
  //       type: "float",
  //     },
  //     {
  //       label: "Availability Time",
  //       key: "availabilityData",
  //       type: "object",
  //     },
  //     {
  //       label: "Rain Protected",
  //       key: "isRainProtected",
  //       type: "string",
  //     },
  //     {
  //       label: "Cafe",
  //       key: "hasCafe",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Address",
  //       key: "address",
  //       type: "string",
  //     },
  //   ],
  //   data: {
  //     city: "Pune",
  //     areaPrice: [
  //       {
  //         area: "7000",
  //         price: {
  //           weekday: {
  //             regularHours: 1200,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 800,
  //             },
  //           },
  //           weekend: {
  //             regularHours: 1300,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 1200,
  //             },
  //           },
  //         },
  //       },
  //     ],
  //     address: "246, Ashtavinayak Rd, Patil Wasti, Patil Nagar, Balewadi, Pune, Maharashtra",
  //     location: {
  //       latitude: "18.583790",
  //       longitude: "73.767052"
  //     },
  //     actualContact: "8888517512",
  //     displayContact: constants.BUISNESS_NUMBER,
  //     partnership: false,
  //     availabilityData: {
  //       openingTime: "",
  //       closingTime: "",
  //       isAvailable24Hours: true,
  //     },
  //     isRainProtected: false,
  //     starRating: "4.5",
  //     hasCafe: false
  //   },
  //   userName: "Runbhumi-Twin-Turf"
  // },
  // {
  //   name: "PUNE TURF",
  //   domainKey: "PUNE TURF",
  //   imageUrl: [
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/pune_turf/pune_turf_1.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/pune_turf/pune_turf_2.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/pune_turf/pune_turf_3.jpg",
  //   ],
  //   fields: [
  //     {
  //       label: "Contact",
  //       key: "actualContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Contact",
  //       key: "displayContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Partnership",
  //       key: "partnership",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Area & Price",
  //       key: "areaPrice",
  //       type: "object",
  //     },
  //     {
  //       label: "Location",
  //       key: "location",
  //       type: "float",
  //     },
  //     {
  //       label: "Availability Time",
  //       key: "availabilityData",
  //       type: "object",
  //     },
  //     {
  //       label: "Rain Protected",
  //       key: "isRainProtected",
  //       type: "string",
  //     },
  //     {
  //       label: "Cafe",
  //       key: "hasCafe",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Address",
  //       key: "address",
  //       type: "string",
  //     },
  //   ],
  //   data: {
  //     city: "Pune",
  //     areaPrice: [
  //       {
  //         area: "7000",
  //         price: {
  //           weekday: {
  //             regularHours: 1200,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 800,
  //             },
  //           },
  //           weekend: {
  //             regularHours: 1300,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 1200,
  //             },
  //           },
  //         },
  //       },
  //     ],
  //     address: "Sr No.38, C NIBM Annex, Cloud 9, Road, opp. RAHEJA VISTAS PHASE-3, Mohammed Wadi, Pune, Maharashtra",
  //     location: {
  //       latitude: "18.472468",
  //       longitude: "73.912167"
  //     },
  //     actualContact: "7020670707",
  //     displayContact: constants.BUISNESS_NUMBER,
  //     partnership: false,
  //     availabilityData: {
  //       openingTime: "5 am",
  //       closingTime: "11 pm",
  //       isAvailable24Hours: false,
  //     },
  //     isRainProtected: false,
  //     starRating: "5.0",
  //     hasCafe: false
  //   },
  //   userName: "PUNE-TURF"
  // },
  // {
  //   name: "DC TURF",
  //   domainKey: "DC TURF",
  //   imageUrl: [
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/dc_turf_pune/dc_1.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/dc_turf_pune/dc_2.jpg",
  //     "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/dc_turf_pune/dc_3.jpg",
  //   ],
  //   fields: [
  //     {
  //       label: "Contact",
  //       key: "actualContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Contact",
  //       key: "displayContact",
  //       type: "link",
  //     },
  //     {
  //       label: "Partnership",
  //       key: "partnership",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Area & Price",
  //       key: "areaPrice",
  //       type: "object",
  //     },
  //     {
  //       label: "Location",
  //       key: "location",
  //       type: "float",
  //     },
  //     {
  //       label: "Availability Time",
  //       key: "availabilityData",
  //       type: "object",
  //     },
  //     {
  //       label: "Rain Protected",
  //       key: "isRainProtected",
  //       type: "string",
  //     },
  //     {
  //       label: "Cafe",
  //       key: "hasCafe",
  //       type: "boolean",
  //     },
  //     {
  //       label: "Address",
  //       key: "address",
  //       type: "string",
  //     },
  //   ],
  //   data: {
  //     city: "Pune",
  //     areaPrice: [
  //       {
  //         area: "7000",
  //         price: {
  //           weekday: {
  //             regularHours: 1200,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 800,
  //             },
  //           },
  //           weekend: {
  //             regularHours: 1300,
  //             happyHours: {
  //               range: "6:00AM-6:00PM",
  //               price: 1200,
  //             },
  //           },
  //         },
  //       },
  //     ],
  //     address: "Sinhgad Rd, near Brahma hotel, Manik Baug, Anand Nagar, Pune, Maharashtra",
  //     location: {
  //       latitude: "18.472937",
  //       longitude: "73.818689"
  //     },
  //     actualContact: "9021519847",
  //     displayContact: constants.BUISNESS_NUMBER,
  //     partnership: false,
  //     availabilityData: {
  //       openingTime: "5 am",
  //       closingTime: "11 pm",
  //       isAvailable24Hours: false,
  //     },
  //     isRainProtected: true,
  //     starRating: "4.0",
  //     hasCafe: true
  //   },
  //   userName: "DC-TURF"
  // },
   {
    name: "City Sports Arena Turf",
    domainKey: "City Sports Arena Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/city_sports_arena_pune/citysp_1.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
        {
          area: "7875",
          pitchDimensions: {length: 105, width: 75, height: 25},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800,
              },
            },
            weekend: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800,
              },
            },
          },
        },
      ],
      address: "J R D Tata Flyover, Near Siddhi Lawn, Nashik Phata, Pimple Gurav, Pimpri-Chinchwad",
      location: {
        latitude: "18.51957",
        longitude: "73.85535"
      },
      actualContact: "9890012999",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "5.0",
      hasCafe: false
    },
    userName: "City-Sports-Arena-Turf"
  },
  {
    name: "Turf Up Karhadi",
    domainKey: "Turf Up Karhadi",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/turf_up_karhadi/turfup_1.png",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/turf_up_karhadi/turfup_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/turf_up_karhadi/turfup_3.png",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 100, width: 60, height: 25},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
          },
        },
      ],
      address: "Sr. No. 72/1A, Opp Eon It Park Phase-2, Behind Gera Park View Society, Eon Free Zone, Kharadi, Pune, Maharashtra 411014",
      location: {
        latitude: "18.550985",
        longitude: "73.934982"
      },
      actualContact: "7066999666",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.5",
      hasCafe: false
    },
    userName: "Turf-Up-Karhadi"
  },
  {
    name: "Turf Up Hinjewadi",
    domainKey: "Turf Up Hinjewadi",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/turf_up_hinjawadi/turfuph_1.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/turf_up_hinjawadi/turfuph_2.jpeg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/turf_up_hinjawadi/turfuph_3.jpeg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 100, width: 60, height: 35},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
          },
        },
      ],
      address: "Next to Ostro Hotel, Wipro Chowk Phase 2 Hinjewadi, Pune, Maharashtra 411057",
      location: {
        latitude: "18.591345",
        longitude: "73.683521"
      },
      actualContact: "8181852121",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.5",
      hasCafe: false
    },
    userName: "Turf-Up-Hinjewadi"
  },
  {
    name: "Nutmeg Arena Turf",
    domainKey: "Nutmeg Arena Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/nutmeg_turf_pune/nutmeg_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/nutmeg_turf_pune/nutmeg_2.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
        {
          area: "6000",
          pitchDimensions: {length: 100, width: 60, height: 25},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1200,
              },
            },
          },
        },
      ],
      address: "Nutmeg Arena Turf, Next To Sai Majestic Building, Shiv Sai Lane, Near Pk Chowk, Pimple Saudagar , Pune 411027",
      location: {
        latitude: "18.593445",
        longitude: "73.794746"
      },
      actualContact: "8830615096",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "6:00 am",
        closingTime: "11:00 pm",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false
    },
    userName: "Nutmeg-Arena-Turf"
  },
  {
    name: "Derby Sports Turf",
    domainKey: "Derby Sports Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/derby_turf_pune/derby_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/derby_turf_pune/derby_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/derby_turf_pune/derby_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
        {
          area: "8000",
          pitchDimensions: {length: 100, width: 80, height: 30},
          price: {
            weekday: {
              regularHours: 1300,
              happyHours: {
                range: "5:00AM-6:00PM",
                price: 1000,
              },
            },
            weekend: {
              regularHours: 1600,
              happyHours: {
                range: "5:00AM-6:00PM",
                price: 1100,
              },
            },
          },
        },
      ],
      address: "Derby Sports Turf, Opp. Dudhane Lawns Behind Cummins College, behind Tree House School, Pune, Maharashtra 411052",
      location: {
        latitude: "18.4841",
        longitude: "73.8151"
      },
      actualContact: "9021625572",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "5:00 am",
        closingTime: "11:00 pm",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "4.5",
      hasCafe: true
    },
    userName: "Derby-Sports-Turf"
  },
  {
    name: "Turf Tiara",
    domainKey: "Turf Tiara",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/tiara_turf_pune/tiara_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/tiara_turf_pune/tiara_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/tiara_turf_pune/tiara_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
        {
          area: "7800",
          pitchDimensions: {length: 110, width: 70, height: 25},
          price: {
            weekday: {
              regularHours: 1000,
              happyHours: {
                range: "5:00AM-6:00PM",
                price: 900,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "5:00AM-6:00PM",
                price: 1000,
              },
            },
          },
        },
      ],
      address: "9/2/2 near sinhagad college Wadgaon Opposite Hotel Relax pune",
      location: {
        latitude: "",
        longitude: ""
      },
      actualContact: "8822882268",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "7:00 am",
        closingTime: "12:00 pm",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false
    },
    userName: "Turf-Tiara"
  },
  {
    name: "Brio Turf",
    domainKey: "Brio Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/brio_turf_pune/brio_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/brio_turf_pune/brio_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/brio_turf_pune/brio_3.jpg",
        ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
        {
          area: "12000",
          pitchDimensions: {length: 120, width: 100, height: 25},
          price: {
            weekday: {
              regularHours: 1400,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1400,
              },
            },
            weekend: {
              regularHours: 1400,
              happyHours: {
                range: "5:00AM-6:00PM",
                price: 1400,
              },
            },
          },
        },
          {
            area: "6000",
            pitchDimensions: {length: 100, width: 60, height: 25},
            price: {
              weekday: {
                regularHours: 700,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price: 700,
                },
              },
              weekend: {
                regularHours: 700,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price: 700,
                },
              },
            },
          },
        ],
      address: "Behind Bishop’s School, Undri, Pune",
      location: {
        latitude: "18.4577000",
        longitude: "73.9162000"
      },
      actualContact: "8208046986",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "5.0",
      hasCafe: true
    },
    userName: "Brio-Turf"
  },
  {
    name: "Ujs Turf",
    domainKey: "Ujs Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/uj_turf_pune/uj_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/uj_turf_pune/uj_2.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
          {
            area: "7000",
            pitchDimensions: {length: 100, width: 70, height: 25},
            price: {
              weekday: {
                regularHours: 1000,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price: 1000,
                },
              },
              weekend: {
                regularHours: 1000,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price:1000,
                },
              },
            },
          },
        ],
      address: "Uj's Turf,near Indira College, Mumbai - Bangalore Express Highway,Tathawade, Pune, India, Maharashtra",
      location: {
        latitude: "18.607622",
        longitude: "73.751495"
      },
      actualContact: "7507139408",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: true
    },
    userName: "Ujs-Turf"
  },
  {
    name: "Trooferz Turf",
    domainKey: "Trooferz Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/trooferz_turf_pune/trooferz_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/trooferz_turf_pune/trooferz_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/trooferz_turf_pune/trooferz_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
          {
            area: "3500",
            pitchDimensions: {length: 70, width: 50, height: 35},
            price: {
              weekday: {
                regularHours: 1250,
                happyHours: {
                  range: "6:00AM-5:00PM",
                  price: 900,
                },
              },
              weekend: {
                regularHours: 1350,
                happyHours: {
                  range: "12:00PM-5:00PM",
                  price:1250,
                },
              },
            },
          },
          {
            area: "7000",
            pitchDimensions: {length: 140, width: 50, height: 35},
            price: {
              weekday: {
                regularHours: 2500,
                happyHours: {
                  range: "6:00AM-5:00PM",
                  price: 1800,
                },
              },
              weekend: {
                regularHours:2700,
                happyHours: {
                  range: "12:00PM-5:00PM",
                  price:2500,
                },
              },
            },
          },
        ],
      address: "Sports Turf & Club, Pan Card Club Road, Near Solitaire Business Hub ­III, Baner, Pune, Maharashtra 411045",
      location: {
        latitude: "18.557200",
        longitude: "73.784900"
      },
      actualContact: "8080876896",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "6:00 AM",
        closingTime: "1:00 AM",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "4.0",
      hasCafe: false
    },
    userName: "Trooferz-Turf"
  },
  {
    name: "Matchday Arena",
    domainKey: "Matchday Arena",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/matchday_arena_pune/matchday_1.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
        // {
        //   area: "33600",
        //   price: {
        //     weekday: {
        //       regularHours: 3500,
        //       happyHours: {
        //         range: "6:00AM-6:00PM",
        //         price: 3300,
        //       },
        //     },
        //     weekend: {
        //       regularHours: 3500,
        //       happyHours: {
        //         range: "6:00AM-6:00PM",
        //         price:3300,
        //       },
        //     },
        //   },
        // },
          {
            area: "16800",
            pitchDimensions: {length: 120, width: 40, height: 30},
            price: {
              weekday: {
                regularHours: 2000,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price: 1800,
                },
              },
              weekend: {
                regularHours: 2000,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price:1800,
                },
              },
            },
          },
          {
            area: "8400",
            pitchDimensions: {length: 120, width: 70, height: 30},
            price: {
              weekday: {
                regularHours: 1200,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price: 1200,
                },
              },
              weekend: {
                regularHours:1200,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price:1000,
                },
              },
            },
          },
        ],
      address: "Nandan Inspera, Shankar Kalat Nagar, Wakad, Pimpri-Chinchwad, Maharashtra 411057, Pune, India, Maharashtra",
      location: {
        latitude: "18.600040",
        longitude: "73.76641"
      },
      actualContact: "8799931421 ",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "6:00 AM",
        closingTime: "11:00 AM",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "4.5",
      hasCafe: true
    },
    userName: "Matchday-Arena"
  },
  {
    name: "Supernova Arena",
    domainKey: "Supernova Arena",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/supernova_arena_pune/supernova_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/supernova_arena_pune/supernova_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/supernova_arena_pune/supernova_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
          {
            area: "16000",
            pitchDimensions: {length: 100, width: 80, height: 40},
            price: {
              weekday: {
                regularHours: 1000,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price: 1000,
                },
              },
              weekend: {
                regularHours: 1000,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price:1000,
                },
              },
            },
          },
        ],
      address: "KESHAVNAGAR MANJARI ROAD LONKAR WASTI NEXT TO 23 EAST LOUNGE PUNE, Pune, India, Maharashtra",
      location: {
        latitude: "18.528291",
        longitude: "73.953514"
      },
      actualContact: "7841814393",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: false,
      starRating: "4.5",
      hasCafe: false
    },
    userName: "Supernova-Arena"
  },
  {
    name: "Players Sports Turf",
    domainKey: "Players Sports Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/players_turf_pune/players_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/PuneTurfs/players_turf_pune/players_2.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Pune",
      areaPrice: [
          {
            area: "15600",
            pitchDimensions: {length: 170, width: 90, height: 40},
            price: {
              weekday: {
                regularHours: 1400,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price: 1400,
                },
              },
              weekend: {
                regularHours: 1400,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price:1400,
                },
              },
            },
          },
          {
            area: "7800",
            pitchDimensions: {length: 90, width: 85, height: 40},
            price: {
              weekday: {
                regularHours: 1000,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price: 1000,
                },
              },
              weekend: {
                regularHours: 1000,
                happyHours: {
                  range: "6:00AM-6:00PM",
                  price:1000,
                },
              },
            },
          },
        ],
      address: "Near Brahma Suncity, Behind Nyati Meadows Society, Galande Patil Estate, Vadgaon Sheri, Pune - 411014",
      location: {
        latitude: "18.5448",
        longitude: "73.9150"
      },
      actualContact: "7373919393",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "6:00 am",
        closingTime: "12:00 pm",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "4.5",
      hasCafe: false
    },
    userName: "Players-Sports-Turf"
  },
  // mhow turfs
  {
    name: "The Highway Turf",
    domainKey: "The Highway Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/MhowTurfs/highway_turf_mhow/highway_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/MhowTurfs/highway_turf_mhow/highway_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/MhowTurfs/highway_turf_mhow/highway_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Mhow",
      areaPrice: [
        {
          area: "10000",
          pitchDimensions: {length: 123, width: 82, height: 50},
          price: {
            weekday: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
            weekend: {
              regularHours: 1200,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
          },
        },
        {
          area: "10000",
          pitchDimensions: {length: 123, width: 82, height: 50},
          price: {
            weekday: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
            weekend: {
              regularHours: 1300,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1100,
              },
            },
          },
        },
      ],
      address: "Near ma bhagwati restaurant pipliya malhar road rau ",
      location: {
        latitude: "22.613890",
        longitude: "75.798217"
      },
      actualContact: "6261838801 / 9926651857",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "",
        closingTime: "",
        isAvailable24Hours: true,
      },
      isRainProtected: true,
      starRating: "5.0",
      hasCafe: true
    },
    userName: "The-Highway-Turf"
  },
  {
    name: "Maheshwari Turf",
    domainKey: "Maheshwari Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/MhowTurfs/maheshwari_turf_mhow/mh_1.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Mhow",
      areaPrice: [
        {
          area: "5000",
          pitchDimensions: {length: 100, width: 50, height: 30},
          price: {
            weekday: {
              regularHours: 800,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800,
              },
            },
            weekend: {
              regularHours: 800,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800,
              },
            },
          },
        },
      ],
      address: "Maheshwari H.S School - Mhow ",
      location: {
        latitude: " 22.552437",
        longitude: "75.756531"
      },
      actualContact: "9907640040",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "2 pm",
        closingTime: "11 pm",
        isAvailable24Hours: false,
      },
      isRainProtected: true,
      starRating: "4.5",
      hasCafe: false
    },
    userName: "Maheshwari-Turf"
  },
  // Mandsaur turfs
  {
    name: "Fields and Feast Turf",
    domainKey: "Fields and Feast Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/MandsaurTurfs/fields_and_feast_turf_mandsaur/Fieldandfeastmds_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/MandsaurTurfs/fields_and_feast_turf_mandsaur/Fieldandfeastmds_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/MandsaurTurfs/fields_and_feast_turf_mandsaur/Fieldandfeastmds_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Mandsaur",
      areaPrice: [
        {
          area: "5000",
          price: {
            weekday: {
              regularHours: 800,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 800,
              },
            },
            weekend: {
              regularHours: 1000,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 1000,
              },
            },
          },
        },
      ],
      address: "Rishabh Vihar Building,Mhow - Neemuch Rd,Near Steel Nagar,Mandsaur",
      location: {
        latitude: "24.088962",
        longitude: "75.053978"
      },
      actualContact: "8982531414",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "6 am",
        closingTime: "12 am",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "5.0",
      hasCafe: true
    },
    userName: "Fields-and-Feast-Turf"
  },
  {
    name: "Smash Turf",
    domainKey: "Smash Turf",
    imageUrl: [
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/MandsaurTurfs/smash_turf_mandsaur/smash_mds_1.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/MandsaurTurfs/smash_turf_mandsaur/smash_mds_2.jpg",
      "https://s3.ap-south-1.amazonaws.com/turfbooking.in-bucket/MandsaurTurfs/smash_turf_mandsaur/smash_mds_3.jpg",
    ],
    fields: [
      {
        label: "Contact",
        key: "actualContact",
        type: "link",
      },
      {
        label: "Contact",
        key: "displayContact",
        type: "link",
      },
      {
        label: "Partnership",
        key: "partnership",
        type: "boolean",
      },
      {
        label: "Area & Price",
        key: "areaPrice",
        type: "object",
      },
      {
        label: "Location",
        key: "location",
        type: "float",
      },
      {
        label: "Availability Time",
        key: "availabilityData",
        type: "object",
      },
      {
        label: "Rain Protected",
        key: "isRainProtected",
        type: "string",
      },
      {
        label: "Cafe",
        key: "hasCafe",
        type: "boolean",
      },
      {
        label: "Address",
        key: "address",
        type: "string",
      },
    ],
    data: {
      city: "Mandsaur",
      areaPrice: [
        {
          area: "2400",
          price: {
            weekday: {
              regularHours: 400,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 500,
              },
            },
            weekend: {
              regularHours: 600,
              happyHours: {
                range: "6:00AM-6:00PM",
                price: 700,
              },
            },
          },
        },
      ],
      address: "Aditya Tower steel nagar near ashok tol kata, Mhow Neemuch Road, Mandsaur ",
      location: {
        latitude: "24.088795128",
        longitude: "75.0540974"
      },
      actualContact: "9302370742 , 9174370479",
      displayContact: constants.BUISNESS_NUMBER,
      partnership: false,
      availabilityData: {
        openingTime: "6 am",
        closingTime: "1 am",
        isAvailable24Hours: false,
      },
      isRainProtected: false,
      starRating: "3.5",
      hasCafe: false
    },
    userName: "Smash-Turf"
  },
];
